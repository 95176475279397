export const environment = {
  production: true,
  sign_in: 'https://lmsstage.cirrusapproach.com/users/saml/sign_in',
  sign_out: 'https://lmsstage.cirrusapproach.com/users/logout/',
  profile: 'https://env-ciam-cirrus-aircraft-multisite.pantheonsite.io/account/profile/',
  api_domain: 'https://lmsstage.cirrusapproach.com',
  media_server_domain: 'https://cirrus-lms-api-stage.herokuapp.com',
  sentry_dsn: 'https://21f9dfa487bd42bab64a95992e8f0c4e@o510693.ingest.sentry.io/5658832',
  sentry_environment: 'production',
  sourceVersion: '3fdb200192731a7e68c53775a9eb7349a6b4b926',
  defaultThumbnailCourse:
    'https://cirrusapproachherokuprod.blob.core.windows.net/cirruslmsherokudevcontainer/content-items/images/default-course-thumbnail.jpg',
  stsServer: 'https://cirrusaircraft--cirfullsb.sandbox.my.site.com/cirrusaccount',
  redirectUrl: 'https://lmsstage.cirrusapproach.com/authenticated',
  postLogoutRedirectUri: 'https://lmsstage.cirrusapproach.com/learning-catalog',
  clientId: '3MVG9R6Rj0ExUSsj5TbglPecrU.GojBC0ATRjhXP2cZxEJAzwU6l52geAtbrCHgrOOLdo0g.OE_szEoN7aFCk',
  scope: 'openid profile email',
  responseType: 'id_token token',
  logoutUrl: 'https://env-sso-cirrus-aircraft-multisite.pantheonsite.io/?logout-current-user',
  // Remove me once force completion is live...
  force_completion: 'false',
  fullstoryOrgId: 'o-1NX9CW-na1',
  analyticsEnabled: true,
};
